.chunks {
  min-height: 83vh;
  max-height: 83vh;
  min-width: 22.5vw;
  max-width: 22.5vw;
}

.stars {
  min-height: 4vh;
  max-height: 4vh;
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: left;
  background-color: #464654;
}

.chunkViewerText {
  color: white;
  font-size: 0.8vw;
}

.chunkTexts {
  padding-left: 1vw;
  padding-bottom: 1vh;
  background-color: #464654;
}

.chunkText {
  min-height: 15vh;
  max-height: 15vh;
  overflow: scroll;
  overflow-x: hidden;
}

.title {
    background-color: #464654;
    word-wrap: break-word;
    padding-left: 0.35vw;

}

.chunkStarSize {
    min-height: 1vh;
    max-height: 1vh;
    min-width: 1.5vw;
    max-width: 1.5vw;
}

.chunkObject {
  padding: 1vh;
}

.chunkObjectBackground {
    background-color: #464654;
    padding: 0.5vh;
}

.urisourceLinkIcon {
    min-height: 2.5vmin;
    max-height: 2.5vmin;
    transform: rotate(-45deg);
}

.urisourceLinkIcon:hover {
    cursor: pointer;
}

.passageNumberSection {
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: center;
    min-width: 20vw;
    max-width: 20vw;
}

.passageTriangle {
    min-height: 2vw;
    max-height: 2vw;
}

.passageNumberTitle {
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: left;
    min-width: 10vw;
    max-width: 10vw;
}

.passageNumberTriangle{
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: right;
    min-width: 10vw;
    max-width: 10vw;
}

.passageTriangle:hover {
    cursor: pointer;
}
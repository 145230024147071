.body {
  background-color: #32323c;
  min-height: 85vh;
  max-height: 85vh;
  min-width: 100vw;
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.footer {
  background-color: #32323c;
  min-height: 5vh;
  max-height: 5vh;
  min-width: 100vw;
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.headerBar {
  background-color: #16161c;
  min-height: 10vh;
  max-height: 10vh;
  min-width: 100vw;
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.logo {
  min-width: 50vw;
  max-width: 50vw;
  justify-content: left;
  display: flex;
}

.logoImage {
  min-height: 7vh;
  max-height: 7vh;
}

.login {
  min-width: 50vw;
  max-width: 50vw;
  justify-content: right;
  display: flex;
}

.circle {
  display: flex;
  min-height: 6vh;
  max-height: 6vh;
  min-width: 4vw;
  max-width: 4vw;
  background-color: green;
  border-radius: 50%;
}

.text {
  margin: auto;
  color: white;
}

.infoOptionsHeader {
  min-height: 10vh;
  max-height: 10vh;
  min-width: 99vw;
  max-width: 99vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.infoWindow {
  min-height: 65vh;
  max-height: 65vh;
  min-width: 99vw;
  max-width: 99vw;
  overflow: scroll;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.headerButtons {
  padding-left: 1vw;
  padding-right: 1vw;
}

.headerButton {
  min-height: 7vh;
  max-height: 7vh;
  min-width: 10vw;
  max-width: 10vw;
  background-color: transparent;
  border-bottom:  0.2vw solid white;
  color: white;
  font-size: 1vw;
}

.headerButton:hover {
    cursor: pointer;
}

.infoBody {
  min-width: 80vw;
  max-width: 80vw;
  padding: 3vh 5vw;
  color: white;
}

.infoHeader {
  font-size: 2vw;
  border-bottom:  0.2vw dotted white;
}

.infoText {
  padding-top: 1vh;
  font-size: 1vw;
}

.info {
  background-color: #32323c;
  min-height: 85vh;
  max-height: 85vh;
  min-width: 100vw;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
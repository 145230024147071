.body {
  background-color: #32323c;
  min-height: 85vh;
  max-height: 85vh;
  min-width: 100vw;
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.footer {
  background-color: #32323c;
  min-height: 5vh;
  max-height: 5vh;
  min-width: 100vw;
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.headerBar {
  background-color: #16161c;
  min-height: 10vh;
  max-height: 10vh;
  min-width: 100vw;
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.logo {
  min-width: 50vw;
  max-width: 50vw;
  justify-content: left;
  display: flex;
}

.logoImage {
  min-height: 7vh;
  max-height: 7vh;
}

.login {
  min-width: 50vw;
  max-width: 50vw;
  justify-content: right;
  display: flex;
}

.circle {
  display: flex;
  min-height: 6vh;
  max-height: 6vh;
  min-width: 4vw;
  max-width: 4vw;
  background-color: green;
  border-radius: 50%;
}

.text {
  margin: auto;
  color: white;
}

.loginForm {
}

.loginBox {
  min-height: 85vh;
  max-height: 85vh;
  min-width: 100vw;
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

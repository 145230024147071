.body {
  background-color: #32323c;
  min-height: 85vh;
  max-height: 85vh;
  min-width: 100vw;
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.footer {
  background-color: #32323c;
  min-height: 5vh;
  max-height: 5vh;
  min-width: 100vw;
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.headerBar {
  background-color: #16161c;
  min-height: 10vh;
  max-height: 10vh;
  min-width: 100vw;
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.logo {
  min-width: 50vw;
  max-width: 50vw;
  justify-content: left;
  display: flex;
}

.logoImage {
  min-height: 7vh;
  max-height: 7vh;
  min-width: 12.5vw;
  max-width: 12.5vw;
  padding-left: 1vw;
}

.loginIconBox {
  min-width: 48vw;
  max-width: 48vw;
  min-height: 9vh;
  max-height: 9vh;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  display: flex;
  color: white;
}

.circle {
  display: flex;
  min-height: 6vmin;
  max-height: 6vmin;
  background-color: #7faf3b;
  border-radius: 50%;
}

.text {
  margin: auto;
  color: white;
  font-size: 2vmin;
}

.textLoginUser {
  margin: auto;
  color: white;
  font-size: 4vmin;
}

.settingsIcon {
}

.settingsIcon:hover {
    cursor: pointer;
}

.dropdown-toggle::after {
  display: none !important;
}

.dropdown-toggle {
    background-color: transparent !important;
    border-color: transparent !important;
}

.dropdown-item:hover {
    cursor: not-allowed;
}

.dropDownItemAvailable:hover {
    cursor: pointer;
}

.loginFillerBox {
  min-width: 1vw;
  max-width: 1vw;
  min-height: 9vh;
  max-height: 9vh;
}
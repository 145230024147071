.body {
  background-color: #32323c;
  min-height: 85vh;
  max-height: 85vh;
  min-width: 100vw;
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.footer {
  background-color: #32323c;
  min-height: 5vh;
  max-height: 5vh;
  min-width: 100vw;
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.headerBar {
  background-color: #16161c;
  min-height: 10vh;
  max-height: 10vh;
  min-width: 100vw;
  max-width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.logo {
  min-width: 50vw;
  max-width: 50vw;
  justify-content: left;
  display: flex;
}

.logoImage {
  min-height: 7vh;
  max-height: 7vh;
}

.logoImageBig {
  min-height: 10vh;
  max-height: 10vh;
  min-width: 6vw;
  max-width: 6vw;
}

.logoImageSmall {
  min-height: 5vh;
  max-height: 5vh;
}

.login {
  min-width: 50vw;
  max-width: 50vw;
  justify-content: right;
  display: flex;
}

.circle {
  display: flex;
  min-height: 6vh;
  max-height: 6vh;
  min-width: 4vw;
  max-width: 4vw;
  background-color: green;
  border-radius: 50%;
}

.oval {
  display: flex;
  min-height: 10vh;
  max-height: 10vh;
  min-width: 10vw;
  max-width: 10vw;
  background-color: #1e1e26;
  border-radius: 50%;
}

.text {
  margin: auto;
  color: white;
  font-size: 1.75vmin;
}

.responseText {
  margin-left: auto;
  margin-top: auto;
  color: white;
}

.bodyChat {
  align-self: flex-end;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  min-height: 85vh;
  max-height: 85vh;
}

.chatWindow {
  min-height: 85vh;
  max-height: 85vh;
  min-width: 38vw;
  max-width: 38vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.buttonOptions {
  min-height: 12vh;
  max-height: 12vh;
  min-width: 40vw;
  max-width: 40vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.buttonInfo {
  display: flex;
  min-height: 5vh;
  max-height: 5vh;
  min-width: 2.5vw;
  max-width: 2.5vw;
  background-color: #1e2626;
  border-radius: 15%;
  font-size: 1.75vw;
}

.buttonInfo:hover {
    cursor: pointer;
}

.chat {
  min-height: 65vh;
  max-height: 65vh;
  min-width: 37vw;
  max-width: 37vw;
  overflow: scroll;
  overflow-x: hidden;
  z-index: 3;
}

.inputChat {
  background-color: #1e2626;
  min-height: 8vh;
  max-height: 8vh;
  min-width: 40vw;
  max-width: 40vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 30% 10vw;
  border-top-left-radius: 30% 10vw;
  border-bottom-right-radius: 30% 10vw;
  border-top-right-radius: 30% 10vw;
}

.chatDivider {
  min-width: 33vw;
  max-width: 33vw;
  min-height: 1vh;
  max-height: 1vh;
  border-color: transparent;
}

.inputFiller {
  min-height: 10vh;
  max-height: 10vh;
  min-width: 3vw;
  max-width: 3vw;
}

.inputField {
  min-height: 10vh;
  max-height: 10vh;
  min-width: 28vw;
  max-width: 28vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.inputField[disabled] {
    pointer-events: none;
    opacity: 0.7;
}

.questionBoxRightIcon[disabled] {
    pointer-events: none;
    opacity: 0.7;
}

.inputButton {
  min-height: 10vh;
  max-height: 10vh;
  min-width: 8vw;
  max-width: 8vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.arrowUp:hover {
    cursor: pointer;
}

.filterQuestion {
  min-height: 10vh;
  max-height: 10vh;
  min-width: 30vw;
  max-width: 30vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.filterQuestionOption {
  border: 0.2vw solid white;
  border-radius: 20%;
  min-height: 8vh;
  max-height: 8vh;
  min-width: 10vw;
  max-width: 10vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.filterQuestionOption:hover {
  cursor: pointer;
}

.filterQuestionFiller {
  min-height: 10vh;
  max-height: 10vh;
  min-width: 2vw;
  max-width: 2vw;
}

.userResponseIcon {
  justify-content: right;
  display: flex;
  padding-right: 1vw;
}

.userResponse {
  background-color: #24242d;
  justify-content: right;
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex;
  min-width: 22.5vw;
  max-width: 22.5vw;
  padding: 1vmin;
  border-radius: 10%;
}

.userResponseFiller {
  min-height: 10vh;
  max-height: 10vh;
  min-width: 5vw;
  max-width: 5vw;
}

.response {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  padding-right: 1vw;
}

.askQuestion {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}

.askQuestionFiller {
  min-height: 10vh;
  max-height: 10vh;
  min-width: 5vw;
  max-width: 5vw;
}

.askQuestionBot {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  display: flex;
  min-width: 22.5vw;
  max-width: 22.5vw;
  background-color: #24242d;
  padding: 1vmin;
  border-radius: 10%;
}

.botText {
  margin-right: auto;
  margin-top: auto;
  color: white;
}

.chunkViewer {
  background-color: #1e1e26;
  min-height: 85vh;
  max-height: 85vh;
  min-width: 29vw;
  max-width: 29vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: scroll;
  overflow-x: hidden;
}

.filterViewer {
  background-color: #1e1e26;
  min-height: 85vh;
  max-height: 85vh;
  min-width: 29vw;
  max-width: 29vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: scroll;
  overflow-x: hidden;
}

.sideFiller {
  min-width: 2vw;
  max-width: 2vw;
  min-height: 85vh;
  max-height: 85vh;
}

::-webkit-scrollbar {
  width: 1.5vmin;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 1 0 6px rgba(127,127,127,1);
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: #f18715;
  -webkit-box-shadow: inset 0 0 6px rgba(127,127,127,0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #f18715;
}

::-webkit-scrollbar-corner {
    background: black;
}

.filterSlider {
    min-width: 15vw;
    max-width: 15vw;
}

.filterOption {
    min-width: 23vw;
    max-width: 23vw;
    border-bottom: 0.2vw dotted white;
}

.filterText {
    color: white;
    min-width: 15vw;
    max-width: 15vw;
    font-size: 2.5vmin;
}

.sliderText {
    color: white;
    padding-left: 1vw;
    padding-right: 1vw;
}

.radioOptions {
    color: white
}

.filters {
    min-height: 85vh;
    max-height: 85vh;
    min-width: 25vw;
    max-width: 25vw;
}

.filterTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
}

.sliderBox {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.radioGroupSlider {
    padding-left: 2vw;
}

.searchBox {
    min-width: 25vw;
    max-width: 25vw;
}

.dropdownList {
    min-width: 23vw;
    max-width: 23vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-bottom: 1vw;
}

.list {
    min-width: 20vw;
    max-width: 20vw;
}

.filterSpeechBubble {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.filterSpeechBubbleText {

}

.filterSpeechBubbleIcons {
    padding-top: 2vmin;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.filterSpeechBubbleIcons[disabled] {
    pointer-events: none;
    opacity: 0.7;
}

.filterSpeechBubbleIcon:hover {
    cursor: pointer;
}

.filterSpeechBubbleIconsFiller {
    min-width: 2vw;
    max-wifth: 2vw;
}

.answeringSpeechBubble {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.answeringSpeechBubbleText {

}

.answeringSpeechBubbleIcons {
    padding-top: 2vmin;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.answeringSpeechBubbleIcons[disabled] {
    pointer-events: none;
    opacity: 0.7;
}

.answeringSpeechBubbleIcon{
}

.answeringSpeechBubbleIcon:hover {
    cursor: pointer;
}

.llmAnswerOptions {
    min-width: 10vw;
    max-width: 10vw;
    min-height: 3vh;
    max-height: 3vh;
    display: flex;
}

.llmAnswerOptionsDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left
}

.llmAnswerOptionsPadding {
    min-width: 3vw;
    max-width: 3vw;
}

.llmAnswerOption {
  display: flex;
  min-height: 3vh;
  max-height: 3vh;
  min-width: 1.5vw;
  max-width: 1.5vw;
  background-color: #1e2626;
  border-radius: 15%;
  font-size: 1.75vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center
}

.llmAnswerOption:hover {
    cursor: not-allowed;
}

.llmAnswerOptionPadding {
  min-width: 1vw;
  max-width: 1vw;
}

.llmAnswerOptionIcon {
  min-width: 1vw;
  max-width: 1vw;
}

.llmAnswerOptionAllowed {
display: flex;
  min-height: 3vh;
  max-height: 3vh;
  min-width: 1.5vw;
  max-width: 1.5vw;
  background-color: #1e2626;
  border-radius: 15%;
  font-size: 1.75vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center
}

.llmAnswerOptionAllowed:hover {
    cursor: pointer;
}

.botTimeInBubble {
    min-width: 21vw;
    max-width: 21vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: right;
    font-size: 1.75vmin;
    color: #8ea0a5;
}

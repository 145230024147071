.logoBig {
  justify-content: left;
  display: flex;
  position: absolute;
  top: 30.5vh;
  left: 42.5vw;
  z-index: 2;
}

.triangle {
  position: absolute;
  top: 30.5vh;
  left: 50.5vw;
  border-left: 1vh solid #1e1e26;
  border-right: 1vh solid transparent;
  border-top: 1vh solid transparent;
  border-bottom: 1vh solid #1e1e26;
}

.circleAbsolute {
  top: 22.5vh;
  left: 50.5vw;
  position: absolute;
  justify-content: right;
  display: flex;
}

.questionBox {
  background-color: #1e2626;
  min-height: 8vh;
  max-height: 8vh;
  min-width: 40vw;
  max-width: 40vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-bottom-left-radius: 30% 10vw;
  border-top-left-radius: 30% 10vw;
  border-bottom-right-radius: 30% 10vw;
  border-top-right-radius: 30% 10vw;
}

.questionBoxLeftIcon {
  min-height: 8vh;
  max-height: 8vh;
  min-width: 5vw;
  max-width: 5vw;
  border-bottom-left-radius: 30% 10vw;
  border-top-left-radius: 30% 10vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
}

.questionBoxInputField {
  min-height: 8vh;
  max-height: 8vh;
  min-width: 30vw;
  max-width: 30vw;
  border-bottom-right-radius: 30% 10vw;
  border-top-right-radius: 30% 10vw;
}

.questionBoxRightIcon {
  min-height: 8vh;
  max-height: 8vh;
  min-width: 5vw;
  max-width: 5vw;
  border-bottom-right-radius: 30% 10vw;
  border-top-right-radius: 30% 10vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
}

.questionCenterLayout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.generalOptions {
  padding: 0.5vmin;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.generalButtonInfo {
  display: flex;
  min-height: 5vh;
  max-height: 5vh;
  min-width: 2.5vw;
  max-width: 2.5vw;
  background-color: #1e2626;
  border-radius: 15%;
  font-size: 1.75vw;
}

.generalButtonInfo:hover {
    cursor: pointer;
}

.generalButtonRestart {
  min-height: 5vh;
  max-height: 5vh;
  min-width: 2.5vw;
  max-width: 2.5vw;
  background-color: #1e2626;
  border-radius: 15%;
  font-size: 1.75vw;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.generalButtonRestart:hover {
    cursor: pointer;
}

.generalButtonSave {
    min-height: 5vh;
  max-height: 5vh;
  min-width: 2.5vw;
  max-width: 2.5vw;
  background-color: #1e2626;
  border-radius: 15%;
  font-size: 1.75vw;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.generalButtonSave:hover {
    cursor: not-allowed;
}

.generalButtonMic {
    min-height: 5vh;
  max-height: 5vh;
  min-width: 2.5vw;
  max-width: 2.5vw;
  background-color: #1e2626;
  border-radius: 15%;
  font-size: 1.75vw;
  color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.generalButtonMic:hover {
    cursor: not-allowed;
}

.generalButtonFiller {
  min-height: 5vh;
  max-height: 5vh;
  min-width: 1.5vw;
  max-width: 1.5vw;
}

.generalButtonText {
  margin: auto;
  color: white;
  font-size: 3.5vmin;
}

.cameleonText {
  margin: auto;
  color: white;
  font-size: 2.5vmin;
}